<template>
  <div>
    <RevForm
      ref="form"
      :initial-values="initialValues"
      method="POST"
      :validate="validators"
      @submit="handleSubmit"
    >
      <template #default="{ values, errors, hasError }">
        <h2 class="heading-3">
          {{ i18n(deliveryTitle) }}
        </h2>
        <RevCard
          class="mt-16 flex flex-col items-start gap-5 p-24 lg:flex-row lg:items-center lg:justify-between lg:gap-6"
          data-qa="shippingAddressSummary"
        >
          <Address
            key="shipping-address"
            :address="deliveryAddress"
            :with-details="!addressStore.hasCollectionPoint"
            with-phone
          />

          <RevButtonTiny
            data-qa="editShippingAddress"
            :icon="IconEdit"
            type="button"
            variant="secondary"
            @click="router.push({ name: CHECKOUT.SHIPPING_ADDRESS })"
          >
            {{ i18n(deliveryEditButton) }}
          </RevButtonTiny>
        </RevCard>

        <h2 class="heading-3 mt-56">
          {{ i18n(translations.billingAddressTitle) }}
        </h2>
        <RevCard
          class="mt-16 flex flex-col items-start gap-5 p-24 lg:flex-row lg:items-center lg:justify-between lg:gap-6"
          data-qa="billingAddressSummary"
        >
          <Address
            key="billing-address"
            :address="addressStore.billing"
            with-details
          />
          <RevButtonTiny
            data-qa="editBillingAddress"
            :icon="IconEdit"
            type="button"
            variant="secondary"
            @click="router.push({ name: CHECKOUT.BILLING_ADDRESS })"
          >
            {{ i18n(translations.billingAddressEditButton) }}
          </RevButtonTiny>
        </RevCard>

        <Delivery @update="handleDeliveryUpdate" />

        <div v-if="userInformationStore.isFormRequired" class="mt-56">
          <h2 class="heading-3">
            {{ i18n(translations.userInformationTitle) }}
          </h2>
          <RevCard class="mt-16 p-24">
            <UserInformationFields
              v-model:birthdate="values.birthdate"
              v-model:national-id="values.nationalId"
              :country="addressStore.billing.country"
              :errors="errors"
              :with-national-id="
                withNationalId || userInformationWithNationalId
              "
            />

            <div class="mt-56 flex justify-end">
              <RevButton
                data-qa="submit-button"
                :disabled="isSubmitButtonDisabled(hasError)"
                full-width="responsive"
                :loading="isSubmitting"
                type="submit"
                variant="primary"
              >
                {{ submitButtonTitle }}
              </RevButton>
            </div>
          </RevCard>
        </div>

        <div
          v-if="!userInformationStore.isFormRequired"
          class="mt-56 flex justify-end"
        >
          <RevButton
            ref="submit"
            data-qa="submit-button"
            :disabled="isSubmitButtonDisabled(hasError)"
            full-width="responsive"
            :loading="isSubmitting"
            type="submit"
            variant="primary"
          >
            {{ submitButtonTitle }}
          </RevButton>
        </div>
      </template>
    </RevForm>

    <ReassuranceItems class="mt-56">
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { type Country } from '@backmarket/http-api'
import type {
  Bill,
  DeliverCollectionPoint,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import Address from '@backmarket/nuxt-module-address/Address.vue'
import { InputAddressBirthdateValidators } from '@backmarket/nuxt-module-address/InputAddressBirthdateValidators'
import { InputAddressNationalIdValidators } from '@backmarket/nuxt-module-address/InputAddressNationalIdValidators'
import UserInformationFields from '@backmarket/nuxt-module-address/UserInformationFields.vue'
import type { NationalIdCountries } from '@backmarket/nuxt-module-address/nationalId'
import { isNationalIdRequiredForCountry } from '@backmarket/nuxt-module-address/utils/nationalId/isNationalIdRequiredForCountry'
import { isNationalIdShownForBilling } from '@backmarket/nuxt-module-address/utils/nationalId/isNationalIdShownForBilling'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'
import { RevForm, makeValidate } from '@ds/components/Form'
import { IconEdit } from '@ds/icons/IconEdit'

import { useSwapStore } from '~/scopes/checkout/stores/swapStore'
import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { CHECKOUT_LOG_TYPES } from '../../config/constants'
import { CHECKOUT } from '../../routes-names'
import { useAddressStore } from '../../stores/addressStore'
import { useCartStore } from '../../stores/cartStore'
import { useUserInformationStore } from '../../stores/userInformationStore'

import translations from './AddressConfirmation.translations'
import Delivery from './Delivery.vue'

type FormValues = {
  country: string
  birthdate: string
  nationalId?: string
}

const form = ref<HTMLFormElement | null>(null)

const isSubmitting = ref(false)
const hasCollectionPointError = ref(false)

const i18n = useI18n()
const tracking = useTracking()
const route = useRoute()
const router = useRouter()
const logger = useLogger()

const { openErrorToast } = useTheToast()

const cartStore = useCartStore()
const addressStore = useAddressStore()
const userInformationStore = useUserInformationStore()
const swapStore = useSwapStore()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

onMounted(async () => {
  tracking.trackFunnel(cartStore.trackingData(CHECKOUT.ADDRESS_CONFIRMATION))

  if (!isEmpty(route.query.missingData)) {
    openErrorToast({
      content: i18n(translations.missingData),
    })

    // Trigger the form submit to display the missing fields error
    form.value?.submit()
  }
})

const validators = makeValidate<FormValues>({
  ...insertIf(userInformationStore.isFormRequired, {
    birthdate: InputAddressBirthdateValidators(i18n),
    ...insertIf(
      !isEmpty(addressStore.billing.customerIdNumber),
      InputAddressNationalIdValidators(i18n),
    ),
  }),
})

const initialValues = computed((): FormValues => {
  const nationalId = addressStore.billing.customerIdNumber

  return {
    country: addressStore.billing.country || '',
    birthdate: userInformationStore.birthdate || '',
    // isEmpty from lodash does not tell Typescript that the variable can no longer be null
    // More info: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/45521
    ...insertIf(!isEmpty(nationalId), { nationalId } as { nationalId: string }),
  }
})

const withNationalId = computed(() => {
  return isNationalIdRequiredForCountry(
    addressStore.billing.country as NationalIdCountries,
  )
})

const userInformationWithNationalId = computed(() => {
  return isNationalIdShownForBilling(addressStore.billing.country as Country)
})

const deliveryAddress = computed(() => {
  // Since we are checking if it has collectionPoint this can't be undefined
  return addressStore.hasCollectionPoint
    ? (addressStore.collectionPoint as DeliverCollectionPoint)
    : addressStore.shipping
})

const deliveryTitle = computed(() => {
  return addressStore.hasCollectionPoint
    ? translations.collectionPointAddressTitle
    : translations.shippingAddressTitle
})

const deliveryEditButton = computed(() => {
  return addressStore.hasCollectionPoint
    ? translations.collectionPointAddressEditButton
    : translations.shippingAddressEditButton
})

const submitButtonTitle = computed(() => {
  return i18n(translations.submitButton)
})

const isSubmitButtonDisabled = (hasError?: boolean) =>
  userInformationStore.isFormRequired && (isSubmitting.value || hasError)

const handleUserInformationSubmit = async (values: FormValues) => {
  tracking.trackFormSubmit({
    name: 'Address submit',
    address: { ...values },
    type: CHECKOUT_LOG_TYPES.ADDRESS_SUBMIT_CONFIRMATION,
  })

  isSubmitting.value = true

  try {
    const address: Bill = {
      ...addressStore.billing,
      ...values,
      countryDialInCode: addressStore.shipping.countryDialInCode,
      phone: addressStore.shipping.phone,
      ...insertIf(
        userInformationWithNationalId.value && !isEmpty(values.nationalId),
        {
          customerIdNumber: values.nationalId as string,
        },
      ),
      ...insertIf(withNationalId.value && !isEmpty(values.nationalId), {
        customerIdNumber: values.nationalId as string,
      }),
    }

    const formType = userInformationStore.isFormRequired
      ? userInformationStore.formType
      : undefined

    if (cartStore.hasBouyguesMobilePlan) {
      await addressStore.saveBouyguesAddress({
        address,
        isShipping: false,
        isBilling: true,
      })
    }

    await addressStore.saveAddress({
      address,
      formType,
      isShipping: false,
      isBilling: true,
    })

    await cartStore.fetchCart()

    return true
  } catch (error) {
    const err = error as Record<string, unknown>

    if (err?.status === 400) {
      logger.error('[Checkout] Error while shubmitting user information')
    } else {
      await handleUnauthorizedUser(err)
    }

    return false
  } finally {
    isSubmitting.value = false
  }
}
const handleBuybackAddressCreation = async () => {
  isSubmitting.value = true
  const values = addressStore.shipping

  try {
    await addressStore.createBuybackAddress({
      ...values,
    })

    return true
  } catch (err) {
    const error = err as Error
    logger.error('[BUYBACK][SWAP] Error while submitting customer address', {
      error,
    })

    return false
  } finally {
    isSubmitting.value = false
  }
}

const handleSubmit = async (values: FormValues) => {
  tracking.trackClick({
    zone: 'cart',
    name: '3_shpping-confirm_cta_continue',
  })

  // Check if collection point option has a selected collection point
  if (
    cartStore.isSelectedCollectionPointMissing &&
    cartStore.optionWithMissingCollectionPointSelected
  ) {
    hasCollectionPointError.value = true
    const firstOption = cartStore.optionWithMissingCollectionPointSelected

    const element = document.getElementById(
      `cart-shipping-option-${firstOption[0]}-${firstOption[1][0]?.choice?.shippingId}`,
    )
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' })

    return
  }

  if (userInformationStore.isFormRequired) {
    const isSuccess = await handleUserInformationSubmit(values)

    if (!isSuccess) {
      openErrorToast({
        title: i18n(translations.submitSwapInfoTitle),
        content: i18n(translations.submitSwapInfoError),
      })

      return
    }
  }

  if (swapStore.hasOffer) {
    const isSuccess = await handleBuybackAddressCreation()

    if (!isSuccess) {
      openErrorToast({
        title: i18n(translations.submitSwapInfoTitle),
        content: i18n(translations.submitSwapInfoError),
      })

      // returning as we don't want the sale to go through
      // if no buyback address has been created
      return
    }
  }

  router.push({ name: CHECKOUT.PAYMENT })
}

const handleDeliveryUpdate = (updating: boolean) => {
  isSubmitting.value = updating
}
</script>
